<template>
  <el-dialog
    :mask-closable="false"
    :visible="visible"
    width="484"
    :title="isAdd ? '新增部门' : '修改部门'"
    class="yt-dialog yt-dialog-default"
    @on-cancel="close"
    @close="close"
  >
    <el-form ref="departmentForm" :model="departmentForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="名称" prop="name">
        <el-input :maxlength="30" show-word-limit v-model="departmentForm.name" placeholder="请输入部门名称" />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="departmentForm.email" placeholder="请输入邮箱" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-Button @click="close" class="modal-btn">取消</el-Button>
      <el-Button type="primary" @click="appendOrModify" class="modal-btn">确定</el-Button>
    </div>
  </el-dialog>
</template>

<script>
import departmentApi from '@api/department'

export default {
  name: 'DepartmentModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    parentId: {
      type: Number
    },
    department: {
      type: Object
    }
  },
  data: function() {
    const that = this

    return {
      visible: this.value,
      loading: false,
      // 部门表单数据（需要那么多字段吗）
      departmentForm: this.isAdd
        ? {
            name: '',
            email: '',
            parentId: this.parentId
          }
        : this.department,
      ruleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.ruleValidate.name.error = true
              callback(new Error('部门名称不能为空'))
            } else if (value.length > 30) {
              that.ruleValidate.name.error = true
              callback(new Error('部门名称不超过 30 字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        email: [
          {
            type: 'email',
            message: '请输入正确格式的邮箱'
            // validator: (rule, value, callback) => {
            //   let regEmail = /[A-Za-z0-9\.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            //   if (regEmail.test(value)) {
            //     callback()
            //   } else {
            //     return callback(newPaper Error('请输入正确的邮箱'))
            //   }
            // }
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    appendOrModify() {
      //空字符串报错
      if (this.departmentForm.name.trim() === '') {
        this.$message.error('部门名称不能为空')
        return
      }
      // if (this.departmentForm.email.trim() === '') {
      //   this.$message.error('邮箱不能为空')
      //   return
      // }
      if (this.departmentForm.email !== null) {
        if (this.departmentForm.email.trim() === '') {
          this.departmentForm.email = null
        }
      }

      const promise = this.isAdd ? departmentApi.addDepartment(this.departmentForm) : departmentApi.modifyDepartment(this.departmentForm)

      promise.then(res => {
        if (res.res === true) {
          this.$message.success(this.isAdd ? '创建成功' : '修改成功')
          this.$emit('on-success')
          this.close()
        }
      })
    }
  },
  watch: {
    value(val) {
      this.visible = val

      if (this.isAdd) {
        this.$refs.departmentForm.resetFields()
      }
    },
    department(department) {
      this.departmentForm = department
    },
    parentId(parentId) {
      this.departmentForm.parentId = parentId
    }
  }
}
</script>

<style lang="less" scoped>
.member-modal ::v-deep .ivu-modal {
  .ivu-modal-footer {
    .modal-btn:first-child {
      margin-right: 20px;
    }

    .modal-btn {
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
}
</style>
