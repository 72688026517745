<template>
  <Modal v-model="IsImport" footer-hide width="484" class="modal" @on-cancel="close" :mask-closable="false">
    <div class="title">导入员工</div>
    <div class="post">
      <span>岗位:&nbsp;&nbsp;&nbsp;</span>
      <el-cascader size="small" @change="getValue" :props="{ checkStrictly: true, expandTrigger: 'hover' }" :options="postData" v-model="postId" />
    </div>
    <div class="post">
      <span>部门:&nbsp;&nbsp;&nbsp;</span>
      <el-cascader size="small" :props="{ checkStrictly: true, expandTrigger: 'hover' }" :options="departMent" v-model="departmentId" />
    </div>
    <div class="importFile">
      <span>文件:&nbsp;&nbsp;&nbsp;</span>
      <Upload style="width: 192px" :before-upload="beforUpload" type="drag" action="" v-if="file === null">
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff" />
          <p>请上传excel格式的文件</p>
        </div>
      </Upload>
      <Tag style="width: 192px" v-else type="dot" closable color="primary" @on-close="file = null">{{ file.name }}</Tag>
    </div>
    <div class="model" v-if="file === null">
      <p>支持扩展名：.xls .xlsx</p>
      <a :href="modelDownloadUrl">下载模板</a>
    </div>
    <div class="footer">
      <Button @click="close">取消</Button>
      <Button type="primary" :loading="loading" @click="submit">确认</Button>
    </div>
  </Modal>
</template>

<script>
import functionApi from '@api/functionApi'
import staff from '../../api/staff'
import config from '../../config/config'
import user from '../../api/user'
export default {
  name: 'importModal',
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      IsImport: this.value,
      postId: 0,
      postData: [],
      loading: false,
      file: null,
      departmentId: 0,
      departMent: [],
      downloadUrl: config.downMB
    }
  },
  computed: {
    modelDownloadUrl() {
      return `${config.baseUrl}/user/api/v1/files/download/excel/auth`
    }
  },
  mounted() {
    this.getDepartMent() // 获取部门
    this.getPostData()
  },
  methods: {
    getValue(node) {
      // console.log(node)
      // console.log(this.postId)
    },
    getDepartMent() {
      this.departMent = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let arr = JSON.stringify(res.res)
        this.departMent.push(
          JSON.parse(
            arr
              .replace(/children/g, 'children')
              .replace(/departmentId/g, 'value')
              .replace(/name/g, 'label')
          )
        )
        this.getDepartMentList(this.departMent[0])
      })
    },
    getDepartMentList(data) {
      data.children.map(item => {
        if (item.children.length === 0) {
          item.children = null
        } else {
          this.getDepartMentList(item)
        }
      })
    },
    beforUpload(file) {
      let regExcel = file.name.substr(file.name.length - 4, file.name.length - 1)
      if (regExcel.includes('xlsx') || regExcel.includes('xls')) {
        this.file = file
        return false
      } else {
        return this.$message.warning('请上传表格文件...')
      }
    },
    getPostData() {
      functionApi.getAllPostDataById(0).then(res => {
        let arr = JSON.stringify(res.res)
        this.postData = JSON.parse(
          arr
            .replace(/posts/g, 'children')
            .replace(/id/g, 'value')
            .replace(/name/g, 'label')
        )
      })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    submit() {
      if (this.postId === 0) return this.message('请选择岗位...', 'warning')
      if (this.departmentId === 0) return this.message('请选择部门...', 'warning')
      if (this.file === null) return this.message('请上传文件', 'warning')
      this.loading = true
      let payload = {
        postId: this.postId[this.postId.length - 1],
        departmentId: this.departmentId[this.departmentId.length - 1]
      }
      let formData = new FormData()
      formData.append('file', this.file)
      staff
        .importStarff(payload, formData)
        .then(res => {
          if (res.res.failReport === '全部导入成功!') {
            this.$message({ message: `${res.res.failReport}`, type: 'success' })
          } else {
            this.$message.error(res.res.failReport)
            // this.$message.success('成功导入' + res.res.successCount + '条数据')
          }
        })
        .finally(() => {
          this.file = null
          this.IsImport = false
          this.$emit('importSuccess')
          this.loading = false
        })
    },
    close() {
      this.IsImport = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>
.modal {
  font-weight: 500;
  font-size: 14px;
  color: #666;
  display: flex;
  text-align: center;
  .title {
    height: 45px;
    font-size: 18px;
    font-weight: 700;
  }
  .post {
    margin: 20px 0;
  }
  .importFile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
    ::v-deep .ivu-upload .ivu-upload-drag {
      width: 215px;
      height: 100px;
    }
  }
  .model {
    margin: 10px 0 20px 0;
  }
  .footer {
    text-align: center;
    button {
      width: 86px;
      height: 35px;
    }
    button:first-child {
      margin-right: 15px;
      &:hover {
        outline: none;
        box-shadow: none;
        background: #d2d2d2;
        border: 1px solid #d2d2d2;
        color: #333;
      }
    }
  }
}
</style>
